/**
 * Sliders using Swiper API.
 * Read more about the API at @link https://swiperjs.com/api/
 */
'use strict';

import { Swiper, Pagination, Navigation, Autoplay } from 'swiper/js/swiper.esm';

Swiper.use([Pagination]);
Swiper.use([Navigation]);
Swiper.use([Autoplay]);


class BannerSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        slidesPerView: 'auto',
        initialSlide: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        centeredSlides: true,
        breakpoints: {
          576: {
            spaceBetween: 30,
          },
        },
        observer: true,
        observeParents: true,
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    return super(sliderContainer, swiperParams);
  }
}

class TestimonialsSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        slidesPerView: 'auto',
        initialSlide: 1,
        loop: true,
        spaceBetween: 15,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          576: {
            spaceBetween: 30,
          },
        },
        observer: true,
        observeParents: true,
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    return super(sliderContainer, swiperParams);
  }
}

class PartnersSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        autoplay: {
          delay: 1500,
          disableOnInteraction: false,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          980: {
            slidesPerView: 5,
            spaceBetween: 15,
          }
        },
        observer: true,
        observeParents: true,
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    return super(sliderContainer, swiperParams);
  }
}

class BlogSlider extends Swiper {
  constructor(...args) {
    let sliderContainer;
    let swiperParams;
    if (args.length === 1) {
      sliderContainer = args[0];
      swiperParams = {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          980: {
            slidesPerView: 3,
            spaceBetween: 15,
          }
        },
        observer: true,
        observeParents: true,
      };
    } else {
      [sliderContainer, swiperParams] = args;
    }
    return super(sliderContainer, swiperParams);
  }
}

export { BannerSlider, TestimonialsSlider, PartnersSlider, BlogSlider, };
