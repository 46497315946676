'use strict';

const stickyHeader = () => {
  const pageHeader = document.querySelector('.page-header');
  const actionBar = document.querySelector('.page-action-bar');
  const bannerSection = document.querySelector('.banner-section');
  let isSticky = false;

  const handler = (entries) => {
    if (!entries[0].intersectionRatio != 1) {
      pageHeader.classList.add('page-header--transparent');
      isSticky = true;
    } else if (isSticky) {
      pageHeader.classList.remove('page-header--transparent');
      isSticky = false;
    }
  };

  if (actionBar && bannerSection) {
    const observer = new window.IntersectionObserver(handler);
    observer.observe(actionBar);
  }
};
export { stickyHeader };
